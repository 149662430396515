"use client";

import React from "react";
import Link from "next/link";
import styles from "./NavBar.module.css";
import Image from "next/image";

export default function NavBar() {
  return (
    <nav className={styles.navOpen}>
      <ul className={styles.navList}>
        <li>
          <Link className={styles.title} href="/">
            Home
          </Link>
        </li>
        <li>
          <Link className={styles.title} href="/about">
            About
          </Link>
        </li>
        <li>
          <Link className={styles.title} href="/projects">
            Projects
          </Link>
        </li>
        <li>
          <Link className={styles.title} href="/blog">
            Blog
          </Link>
        </li>
        <li>
          <a
            className={styles.title}
            href="https://github.com/jessicacousins"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </li>
        <li>
          <a
            className={styles.title}
            href="https://bank.jessicacousins.tech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Capstone
          </a>
        </li>
        <Link href="/privacy-policy">
          <button className={styles.privacyButton}>View Privacy Policy</button>
        </Link>
        <Image
          className={styles.logoArea}
          src="/logo2.png"
          alt=""
          width={200}
          height={100}
        />
        <div className={styles.name}>Jessica Cousins</div>
      </ul>
    </nav>
  );
}
